//import { useLazyTranslate } from 'react-google-translate'
import { auth } from "../firebase";

export const httpsPostFetch = async (path, data, company) => {
  const token = await auth.currentUser.getIdToken();
  const objetoCompleto = {
    data: data,
    company: company,
  };
  return fetch(path, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(objetoCompleto),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("error", error);
    });
};

export const httpsPostFetchGoogleApi = async (translate) => {
  return fetch(
    "https://translation.googleapis.com/language/translate/v2?key=AIzaSyDGRZ2ExoxN9rK5O8Vf2-yY3lBpAffbgMg&source=en&target=es&q=" +
      translate,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.data) {
        return response.data.translations[0].translatedText;
      } else {
        return "";
      }
    });
};

export const httpsPostFilesFetch = async (path, data, files, company) => {
  const base64Files = await transformMultipleFilesToBase64(files);
  const objetoCompleto = {
    data: data,
    files: base64Files,
    company: company,
  };
  const token = await auth.currentUser.getIdToken();
  return fetch(path, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(objetoCompleto),
  }).then((response) => {
    return response;
  });
};

const transformMultipleFilesToBase64 = async (files) => {
  const base64Files = [];
  const promises = [];
  await files.map(async (file) => {
    const reader = new FileReader();
    await reader.readAsDataURL(file.file);
    const newPromise = new Promise((resuelve) => {
      reader.onloadend = () => {
        let base64data = reader.result;
        base64Files.push({
          file: base64data,
          pathFile: file.path,
          name: file.name,
          type: file.type,
        });
        resuelve();
      };
    });
    promises.push(newPromise);
  });
  await Promise.all(promises);
  return base64Files;
};

export const responseBody = async (response) => {
  const json = await response.json();
  return json;
};
