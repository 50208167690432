import { put, call, select } from "@redux-saga/core/effects";
import {
  httpsPostFetch,
  httpsPostFetchGoogleApi,
  responseBody,
} from "../services/https/post";

const getAuth = (state) => state.auth;

export const operationHttpsPost = {
  name: "HTTPS_POST",
  reducer: {
    initialState: { creating: false, created: false, createFailed: false },
    cases: [
      {
        name: "SEND_HTTPS_POST_",
        newState: { creating: true, created: false, createFailed: false },
      },
      {
        name: "SUCCESS_HTTPS_POST_",
        newState: { creating: false, created: true, createFailed: false },
      },
      {
        name: "FAIL_HTTPS_POST_",
        newState: { creating: false, created: false, createFailed: true },
      },
    ],
  },
  action: {
    function: (name) => {
      return {
        sendHttpsPost: (payload) => {
          return { type: "SEND_HTTPS_POST_" + name, payload: payload };
        },
        successHttpsPost: () => {
          return { type: "SUCCESS_HTTPS_POST_" + name };
        },
        failHttpsPost: (payload) => {
          return { type: "FAIL_HTTPS_POST_" + name, payload: payload };
        },
      };
    },
  },
  sagas: {
    sagasActionName: "SEND_HTTPS_POST_",
    function: (name) => {
      return function* sendPost({ payload }) {
        try {
          yield put({ type: "LOAD_ALERT" });
          const { path, dataCreate, company } = payload;
          let data = { ...dataCreate };
          const auth = yield select(getAuth);
          if (
            auth.user.branchSelected &&
            auth.user.branchSelected !== "todos"
          ) {
            data = {
              ...dataCreate,
              branch: auth.user.branchSelected.idBranch,
            };
          }
          data = {
            ...data,
            idResponsibleUser: auth.user.idUser,
            nameResponsibleUser: auth.user.name,
          };
          const response = yield call(httpsPostFetch, path, data, company);
          if (response.status === 200) {
            yield put({ type: "SEND_SUCCESS" });
            yield put({ type: "SUCCESS_HTTPS_POST_" + name });
          } else {
            const responseBody2 = yield call(responseBody, response);
            yield put({
              type: "SEND_FAILED",
              payload: { dataLoad: { errorMessage: responseBody2.message } },
            });
          }
        } catch (e) {
          yield put({
            type: "SEND_FAILED",
            payload: { dataLoad: { errorMessage: "" } },
          });
        }
      };
    },
  },
};
